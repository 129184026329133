@font-face {
font-family: '__customFont_e1c41c';
src: url(/_next/static/media/fbc2ce6af7107f74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: black;
}

@font-face {
font-family: '__customFont_e1c41c';
src: url(/_next/static/media/b1f9da9436ee0f1d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: '__customFont_e1c41c';
src: url(/_next/static/media/3f90e6c778c79bff-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__customFont_e1c41c';
src: url(/_next/static/media/f414022402b85a8a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: light;
}@font-face {font-family: '__customFont_Fallback_e1c41c';src: local("Arial");ascent-override: 79.16%;descent-override: 19.79%;line-gap-override: 0.00%;size-adjust: 121.28%
}.__className_e1c41c {font-family: '__customFont_e1c41c', '__customFont_Fallback_e1c41c'
}

